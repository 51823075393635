import React, { Component } from 'react';
import styled from 'styled-components';

import { Helmet } from 'react-helmet';

import packageJson from '../../../package.json';

import { MODE_WORK, MODE_PAUSE } from '../../constants';

import { defaults, theme } from '../../variables';
import { media } from '../../style-utils';

import { addLeadingZero } from '../../utils';

import Worker from '../../timer.worker';

import Input from '../Input/Input';
import Timer from '../Timer/Timer';
import Button from '../Button/Button';
import Timeline from '../Timeline/Timeline';

import beep from '../../sounds/beep.mp3';

const StyledApp = styled.div`
  display: grid;

  grid-template-rows: min-content min-content min-content 5rem;
  align-content: space-around;

  /* flex-flow: column; */

  /* align-items: center; */
  /* justify-content: space-around; */

  background: ${theme.colors.pomodoro};

  color: ${theme.colors.white};
  font-family: ${theme.typography.fontFamily.sansSerif};
  font-weight: ${theme.typography.fontWeight.regular};

  width: 100%;
  height: 100%;
  padding-top: 3.2rem;

  *::selection {
    color: ${theme.colors.pomodoro};
    background: ${theme.colors.white};
  }

  &:before {
    display: block;
    content: '';

    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2NCAzMiI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzBlYjI2ZDt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0wLDBINjRMMzIsMzJaIi8+PC9zdmc+);
    background-position: center center;
    background-repeat: repeat-x;

    width: 100%;
    height: 3.2rem;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  ${media.tablet`
  `}

  ${media.desktop`
  `}
`;

const Paragraph = styled.div`
  font-size: 2rem;
  line-height: ${30 / 20};

  margin-top: 0;
  text-align: center;

  ${media.laptop`
    font-size: 2.4rem;
    line-height: ${36 / 24};
  `}
`;

const Controls = styled.div`
  display: flex;

  justify-content: center;
`;

const Copyright = styled.div`
  font-size: 1.2rem;
  line-height: ${16 / 12};

  opacity: .5;

  position: absolute;
  left: 50%;
  bottom: 2.4rem;

  transform: translateX(-50%);

  transition: opacity ${theme.transitions.default};

  a {
    color: ${theme.colors.white};
  }

  &:active,
  &:focus,
  &:hover {
    opacity: 1;
  }
`;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: 0,
      isRunning: false,
      hasFinished: false,
      timer: {
        mode: MODE_WORK,
        minutes: defaults.settings[MODE_WORK],
        seconds: 0
      },
      settings: {
        [MODE_WORK]: defaults.settings[MODE_WORK],
        [MODE_PAUSE]: defaults.settings[MODE_PAUSE]
      }
    };

    this.actions = {
      start: {
        fn: this.startTimer,
        text: 'Start',
        title: 'Start the timer'
      },
      pause: {
        fn: this.stopTimer,
        text: 'Pause',
        title: 'Pause the timer'
      },
      reset: {
        fn: this.resetTimer,
        text: 'Reset',
        title: 'Reset the timer'
      },
      skip: {
        fn: this.skipTimer,
        text: 'Skip',
        title: 'Skip the current timer'
      }
    };

    this.handleChange = this.handleChange.bind(this);

    this.setTime = this.setTime.bind(this);
    this.setMode = this.setMode.bind(this);

    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
    this.skipTimer = this.skipTimer.bind(this);

    this.getProgress = this.getProgress.bind(this);
    this.getFavicon = this.getFavicon.bind(this);

    this.audio = React.createRef();
  }

  componentDidMount() {
    this.worker = new Worker();

    this.worker.addEventListener('message', e => {
      const { minutes, seconds } = e.data;

      this.setState({
        timer: {
          ...this.state.timer,
          minutes,
          seconds
        }
      });

      if (minutes <= 0 && seconds <= 0) {
        this.setState({ hasFinished: true });

        this.audio.current.play();

        this.skipTimer();
      }
    });
  }

  handleChange(mode, value) {
    this.setTime(mode, value);
  }

  setTime(mode, value) {
    let newState = {};

    // if mode does not exist in state
    if (!(mode in this.state.settings)) return;

    // if value contains non-digit characters
    if (value.match(/[^\d]/g) !== null) return;

    value = parseInt(value);

    if (mode === this.state.timer.mode) {
      newState.timer = {
        ...this.state.timer,
        minutes: value,
        seconds: 0
      };
    }

    newState.settings = {
      ...this.state.settings,
      [mode]: value
    }

    this.setState(newState);
  }

  setMode(mode) {
    // if mode does not exist in state
    if (!(mode in this.state.settings)) return;

    // if mode is already active
    if (mode === this.getMode()) return;

    this.setState({
      timer: {
        mode,
        minutes: this.state.settings[mode],
        seconds: 0
      }
    });
  }

  getMode(next) {
    if (!next) {
      return this.state.timer.mode;
    } else {
      const modes = Object.keys(this.state.settings);

      const currentIndex = modes.indexOf(this.state.timer.mode);
      const nextIndex = currentIndex + 1 > modes.length - 1 ? 0 : currentIndex + 1;

      return modes[nextIndex];
    }
  }

  startTimer() {
    // if timer is already running
    if (this.state.isRunning) return;

    this.setState({
      isRunning: true,
      hasFinished: false
    });

    this.worker.postMessage({
      action: 'startTimer',
      timer: this.state.timer
    });

    window.onbeforeunload = () => {
      return "The Timer is running. Are you sure you want to close this page?";
    }
  }

  stopTimer() {
    // if timer is not running
    if (!this.state.isRunning) return;

    this.setState({ isRunning: false });

    this.worker.postMessage({
      action: 'stopTimer'
    });

    window.onbeforeunload = null;
  }

  skipTimer() {
    this.stopTimer();

    this.setMode(this.getMode(true));
  }

  resetTimer() {
    this.setState({
      timer: {
        ...this.state.timer,
        minutes: this.state.settings[this.state.timer.mode],
        seconds: 0
      }
    });

    this.stopTimer();
  }

  getProgress(relative) {
    const { mode, minutes, seconds } = this.state.timer;
    const minutesTotal = this.state.settings[mode];

    const divisor = relative ? minutesTotal : Math.ceil(minutesTotal / 5) * 5

    return parseInt((minutes + (seconds / 60)) / divisor * 1000) / 1000;
  }

  getFavicon() {
    const { isRunning, hasFinished } = this.state;

    if (!isRunning && !hasFinished && this.getProgress(true) === 1) return 'favicon';

    if (hasFinished) return 'favicon-notification';

    return `favicon-progress-${Math.max(1, 12 - parseInt(this.getProgress(true) * 12))}`;
  }

  render() {
    const { state, actions } = this;
    const { isRunning, timer, settings } = state;

    let { minutes, seconds } = timer;

    minutes = addLeadingZero(minutes);
    seconds = addLeadingZero(seconds);

    const time = `${minutes}:${seconds}`;

    return (
      <StyledApp>
        <Helmet defer={false}>
          <title>{time} &ndash; Pomodoro</title>
          <link rel="shortcut icon" type="image/png" href={`favicons/${this.getFavicon()}.png`} />
        </Helmet>
        <div className="settings">
          <Paragraph>
            I want to work for <Input type="work" value={settings.work} handleChange={this.handleChange} isDisabled={isRunning} /> minute{state.settings.work > 1 && 's'}.<br />
            Then I'll take a <Input type="pause" value={settings.pause} handleChange={this.handleChange} isDisabled={isRunning} />.
          </Paragraph>
        </div>
        <div className="controls">
          <Timer text={time} />
          <Controls>
            {actions && Object.keys(actions).map(key => {
              let action = actions[key];

              return (
                <Button
                  key={key}

                  text={action.text}
                  title={action.title}

                  handleClick={action.fn.bind(this)}
                />
              );
            })}
          </Controls>
        </div>
        <Timeline length={settings[timer.mode]} progress={this.getProgress()} isDisabled={isRunning} />
        <Copyright>
          {packageJson.version} &copy; <a href="https://mxeff.de" target="blank">mxeff</a>
        </Copyright>
        <audio ref={this.audio} src={beep} />
      </StyledApp>
    );
  }
}

export default App;