import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import numberToWords from 'number-to-words';

import { theme } from '../../variables';
import { media } from '../../style-utils';

const StyledInput = styled.input`
  box-sizing: content-box;

  display: inline-block;

  background: transparent;

  cursor: pointer;

  color: ${theme.colors.white};
  font-weight: ${theme.typography.fontWeight.semibold};
  text-align: center;

  padding-left: .4rem;
  padding-right: .4rem;
  border: none;

  transition: width ${theme.transitions.default};

  &[disabled] {
    border-color: transparent;
  }
`;

const Wrapper = styled.span`
  position: relative;

  &:after {
    display: block;
    content: '';

    background-color: ${theme.colors.white};

    height: .2rem;
    border-radius: .2rem;

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    transition: background-color ${theme.transitions.default};

    ${props => props.disabled && `
      background-color: transparent;
    `}
  }

  ${media.tablet`
    &:after {
      height: .4rem;
      border-radius: .4rem;
    }
  `}
`;

const HiddenWidthHelper = styled.span`
  font-weight: ${theme.typography.fontWeight.semibold};

  visibility: hidden;
  opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
`;

class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isClicked: false,
      isFocused: false,
      width: 0
    };

    this.textInput = React.createRef();
    this.hiddenSpan = React.createRef();

    this.handleClick = this.handleClick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    this.setInputWidth();
  }

  componentDidUpdate() {
    this.setInputWidth();

    if (this.state.isClicked) {
      if(this.textInput.current) {
        this.textInput.current.select();
      }

      this.setState({
        isClicked: false
      });
    }
  }

  handleClick() {
    const { isFocused } = this.state;

    if(isFocused && this.textInput.current) {
      this.textInput.current.select();
    }
  }

  handleFocus() {
    this.setState({
      isClicked: true,
      isFocused: true
    });
  }

  handleBlur() {
    this.setState({ isFocused: false });
  }

  setInputWidth() {
    if (this.hiddenSpan.current) {
      const { isFocused, width } = this.state;
      const { type, value } = this.props;

      let hiddenSpanWidth;

      this.hiddenSpan.current.innerHTML = !isFocused && type === 'pause' ? numberToWords.toWords(value) : value.toString();

      hiddenSpanWidth = this.hiddenSpan.current.offsetWidth;

      if (width !== hiddenSpanWidth) {
        this.setState({
          width: hiddenSpanWidth
        });
      }
    }
  }

  render() {
    const { isFocused } = this.state;

    let { type, value, handleChange, isDisabled } = this.props;

    value = !isFocused && type === 'pause' ? numberToWords.toWords(value) : value.toString();

    let style = {
      width: this.hiddenSpan.current ? this.hiddenSpan.current.offsetWidth + 4 + 'px' : 'auto'
    }

    return (
      <Wrapper disabled={isDisabled}>
        <StyledInput
          className="input"

          ref={this.textInput}
          type="text"
          value={value}

          onBlur={() => this.handleBlur()}
          onChange={(e) => handleChange(type, e.target.value)}
          onClick={() => this.handleClick()}
          onFocus={() => this.handleFocus()}

          style={style}
          disabled={isDisabled}
        />
        <HiddenWidthHelper ref={this.hiddenSpan} />
      </Wrapper>
    );
  }
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

export default Input;