import { MODE_WORK, MODE_PAUSE } from './constants';

export const defaults = {
  settings: {
    [MODE_WORK]: 25,
    [MODE_PAUSE]: 5
  }
};

export const theme = {
  // Breakpoints
  breakpoints: {
    phone: 0,
    tablet: 768,
    laptop: 1024,
    desktop: 1440
  },

  // Colors
  colors: {
    black: 'rgb(0, 0, 0)',
    pomodoro: '#FF6347',
    white: '#FFFFFF'
  },

  // Typography
  typography: {
    fontFamily: {
      sansSerif: '"Work Sans", sans-serif'
    },
    fontWeight: {
      regular: 400,
      semibold: 700
    }
  },

  // Transitions
  transitions: {
    default: '.4s cubic-bezier(.5, 0, 0, 1)',
    timing: '.4s',
    easing: 'cubic-bezier(.5, 0, 0, 1)'
  }
};