import { css } from 'styled-components'

import { theme } from './variables';

export const toEm = value => {
  return value / 16;
}

export const media = Object.keys(theme.breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${toEm(theme.breakpoints[label])}em) {
      ${css(...args)}
    }
  `;

  return accumulator;
}, {});