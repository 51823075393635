import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { theme } from '../../variables';
import { media } from '../../style-utils';

const StyledTimer = styled.div`
  font-family: 'Roboto Mono', monospace;
  font-size: 8.4rem;
  font-weight: ${theme.typography.fontWeight.semibold};
  line-height: ${98 / 84};
  text-align: center;

  ${media.tablet`
    font-size: 12rem;
    line-height: ${144 / 120};
  `}

  ${media.desktop`
    font-size: 18rem;
    line-height: ${210 / 180};
  `}
`;

const Timer = props => {
  return (
    <StyledTimer className="timer">
      {props.text}
    </StyledTimer>
  );
};

Timer.propTypes = {
  text: PropTypes.string.isRequired
};

export default Timer;