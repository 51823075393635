import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { rgba } from 'polished';

import { theme } from '../../variables';

const StyledTimeline = styled.div`
  display: flex;

  justify-content: center;

  padding-bottom: 8rem;

  position: relative;

  overflow: hidden;

  &:before,
  &:after {
    display: block;
    content: '';

    position: absolute;
  }

  &:before {
    border-bottom: .8rem solid ${rgba(`${theme.colors.black}`, .25)};

    left: 0;
    right: 0;
    bottom: 5.4rem;
  }

  &:after {
    border-left: 1.8rem solid transparent;
    border-right: 1.8rem solid transparent;
    border-bottom: 3.6rem solid ${theme.colors.white};

    bottom: 0;
    left: 50%;

    transform: translateX(-50%);
  }
`;

const TrackInner = styled.div`
  display: flex;

  padding-top: 4.2rem;
  margin-left: -.6rem;
  margin-right: -.6rem;
`;

const Segment = styled.div`
  display: flex;

  align-items: flex-end;
`;

const Bar = styled.div`
  background-color: ${theme.colors.white};

  width: .6rem;
  height: 1.2rem;
  margin-right: 2.4rem;
  border-radius: .4rem;

  position: relative

  ${props => props.children && `
    width: 1.2rem;
    height: 2.4rem;
    border-radius: .6rem;
  `}

  span {
    font-size: 3.6rem;
    font-weight: 600;
    line-height: ${42 / 36};

    margin-bottom: .6rem;

    position: absolute;
    left: 50%;
    bottom: 100%;

    transform: translateX(-50%);
  }

  ${Segment}:last-child & {
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Timeline = props => {
  let { length, progress, isDisabled } = props;

  length = Math.ceil(length / 5);

  const position = -50 + (1 - progress) * 100;

  return (
    <StyledTimeline className="timeline">
      <div className="timeline__track" style={{ transform: `translateX(${position}%)` }}>
        <TrackInner disabled={isDisabled}>
          <Bar className="timeline__bar"><span>0</span></Bar>
          {[...Array(length).keys()].map((v, i) => {
            return (
              <Segment className="timeline__segment" key={i}>
                <Bar className="timeline__bar" />
                <Bar className="timeline__bar" />
                <Bar className="timeline__bar" />
                <Bar className="timeline__bar" />
                <Bar className="timeline__bar"><span>{(v + 1) * 5}</span></Bar>
              </Segment>
            );
          })}
        </TrackInner>
      </div>
    </StyledTimeline>
  );
};

Timeline.propTypes = {
  length: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired
};

export default Timeline;