import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { theme } from '../../variables';
import { media } from '../../style-utils';

const StyledButton = styled.button`
  background-color: transparent;

  cursor: pointer;

  color: ${theme.colors.white};
  font-size: 1.6rem;
  font-weight: 600;
  line-height: ${24 / 16};

  padding: .2rem 1.2rem;
  border: .2rem solid transparent;
  border-radius: .6rem;

  transition: background-color .2s ${theme.transitions.easing}, color .2s ${theme.transitions.easing};

  &:active,
  &:focus,
  &:hover {
    background-color: ${theme.colors.white};

    color: ${theme.colors.pomodoro};
  }

  &:not(:last-child) {
    margin-right: .6rem;
  }

  ${media.tablet`
    font-size: 2rem;
    line-height: ${30 / 20};

    &:not(:last-child) {
      margin-right: 1.2rem;
    }
  `}
`;

const Button = props => {
  const { title, text, handleClick } = props;

  return (
    <StyledButton className="button" title={title} onClick={handleClick}>
      {text}
    </StyledButton>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default Button;